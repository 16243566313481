import Header from './components/Header';
import Footer from './components/Footer';
import DLTFinanceComponent from './components/DLTFinanceComponent';

const App = () => {
  return (
    <div className="app">
      <div className="wrapper" id="outer-container">
        <Header />
        <div id="page-wrap">
          <DLTFinanceComponent />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default App;
