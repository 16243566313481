import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import custody_security_lock from '../imgs/custody_security_lock.png';
import custody_regulated from '../imgs/custody_regulated.png';
import ecosystem_bg from '../imgs/ecosystem_bg.png';
import custody_security_details from '../imgs/custody_security_details.png';
import access24 from '../imgs/24access.png';
import seamless_api from '../imgs/seamless_api.png';
import policy_mechanism from '../imgs/policy_mechanism.png';
import specific_requirements from '../imgs/specific_requirements.png';

const CustodyComponent = () => {
  const location = useLocation();
  return (
    <div className="app_custody">
      <div className="wrapper_custody">
        <Header />
        <main className="main">
          <section className="main_custody_body _container">
            <div className="main_custody_block_body">
              <div className="main_custody_body_container">
                <div className="main_custody_body_wrap">
                  <div className="main_custody_body_before">
                    Regulated Multi-layer Security Infrastructure for digital Assets
                  </div>
                  <div className="main_custody_body_title">
                    Institutional grade access to digital asset custody
                  </div>
                  <div className="main_custody_body_text">
                    DLTC offers the safekeeping of multiple digital Assets with state of the art technology and security.
                    Being regulated under the German BaFin, we provide our clients the same regulatory Security as they
                    are used to from traditional financial markets.
                  </div>
                  <div className="main_custody_body_button">
                    <a href={`${location.pathname}#security`} className="menu_link custody_security_button_border">
                      Reed more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="security" className="main_custody_security _container">
            <div className="main_custody_security_body">
              <div className="main_custody_security_container">
                <div className="main_custody_security_title">
                  Multi-layer Security for a maximum of safekeeping
                </div>
                <div className="main_custody_security_text">
                  Proving access to a wallet infrastructure based on multiple storage types and security setups,
                  ranging from air-gapped cold to automatable hot Storage.
                </div>
                <div className="main_custody_security_button">
                  <a href={`${location.pathname}#security_details`} className="menu_link custody_security_button_border">
                    See details
                  </a>
                </div>
              </div>
            </div>
            <div className="main_custody_security_lock">
              <img src={custody_security_lock} alt="security" />
            </div>
          </section>
          <section id="security_details" className="main_custody_security_details _container">
            <img src={custody_security_details} alt="security" />
          </section>
          <section className="main_custody_benefits _container">
            <div className="main_custody_benefits_body">
              <div className="custody_benefits_title">
                Build for financial institutions and professional investors.
              </div>
              <div className="custody_benefits_cards">
                <div className="custody_benefits_card">
                  <div className="custody_benefits_card_icon">
                    <img src={access24} alt="access24" />
                  </div>
                  <div className="custody_benefits_card_text">
                    Up to 24H access
                  </div>
                </div>
                <div className="custody_benefits_card">
                  <div className="custody_benefits_card_icon">
                    <img src={seamless_api} alt="benefits" />
                  </div>
                  <div className="custody_benefits_card_text">
                    Seamless API integration into existing Customer infrastructure
                  </div>
                </div>
                <div className="custody_benefits_card">
                  <div className="custody_benefits_card_icon">
                    <img src={policy_mechanism} alt="benefits" />
                  </div>
                  <div className="custody_benefits_card_text">
                    Transactions Security by extensive policy mechanisms
                  </div>
                </div>
                <div className="custody_benefits_card">
                  <div className="custody_benefits_card_icon">
                    <img src={specific_requirements} alt="benefits" />
                  </div>
                  <div className="custody_benefits_card_text">
                    Reporting according to your specific requirements
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="main_custody_regulated _container">
            <div className="main_custody_regulated_body">
              <div className="custody_regulated_img">
                <img src={custody_regulated} alt="regulated" />
              </div>
              <div className="custody_regulated_info">
                <div className="custody_regulated_info_title">
                  Regulated custody &<br />
                  BaFin Germany
                </div>
                <div className="custody_regulated_info_text">
                  DLTC was granted a preliminary Crypto Asset Custodian License (§ 64y Para. 1 KWG)
                  by the German Federal Financial Supervisory Authority (BaFin).
                  This enables DLT Custody to provide its services as a fully qualified custodian.
                </div>
              </div>
            </div>
          </section>
          <section className="main_custody_ecosystem _container">
            <div className="main_custody_ecosystem_body">
              <div className="custody_ecosystem_info">
                <div className="custody_ecosystem_first">
                  <div className="custody_ecosystem_info_title">
                    Integration in the DLT Finance Ecosystem
                  </div>
                  <ul className="custody_ecosystem_info_list">
                    <li className="custody_ecosystem_list_item">
                      Tailored prime solutions for professional investors
                    </li>
                    <li className="custody_ecosystem_list_item">
                      Seamless integration into the DLT Markets trading environment
                    </li>
                    <li className="custody_ecosystem_list_item">
                      Benefit from a regulated counterparty and multi exchange access
                    </li>
                    <li className="custody_ecosystem_list_item">
                      central onboarding for all services
                    </li>
                  </ul>
                </div>
                <div className="custody_ecosystem_second">
                  <div className="custody_ecosystem_info_title">
                    Institutional Grade Compliance & On Chain Due Diligence
                  </div>
                  <ul className="custody_ecosystem_info_list">
                    <li className="custody_ecosystem_list_item">
                      DLT Custody incorporates the highest Compliance and On Chain Due Diligence standards
                    </li>
                    <li className="custody_ecosystem_list_item">
                      All Assets are subjected to thorough checks before being accepted
                    </li>
                    <li className="custody_ecosystem_list_item">
                      No risk of non compliant assets
                    </li>
                  </ul>
                </div>
              </div>
              <div className="custody_ecosystem_img">
                <img src={ecosystem_bg} alt="ecosystem" />
              </div>
            </div>
          </section>
        </main>
        <div className="overlay">&nbsp;</div>
        <Footer />
      </div>
    </div>
  );
};

export default CustodyComponent;
