import { useLocation } from 'react-router-dom';
import logo from '../imgs/logo.png';
import logo_light from '../imgs/logo_light.png';

/* eslint-disable jsx-a11y/anchor-is-valid */
const Header = () => {
  const location = useLocation();
  let logoImg = logo;
  const pathArr = ['/custody', '/imprint', '/brokerage', '/contact'];
  if (pathArr.includes(location.pathname)) {
    logoImg = logo_light;
  }

  return (
    <header className="header">
      <div className="header_container _container">
        <a href="/" className="header_logo">
          <img src={logoImg} className="header_logo_img" alt="logo" />
        </a>
        <nav id="myLinks" className="header_menu menu">
          <ul className="menu_list">
            <li className="menu_item">
              <a href="/" className="menu_link">
                DLT Finance
              </a>
            </li>
            <li className="menu_item">
              <a href="/#solutions" className="menu_link">
                Solutions
              </a>
            </li>
            <li className="menu_item">
              <a href="/brokerage" className="menu_link">
                Digital Asset Brokerage
              </a>
            </li>
            <li className="menu_item">
              <a href="/custody" className="menu_link">
                Custody
              </a>
            </li>
            <li className="menu_item">
              <a href="/" className="menu_link">
                Staking
              </a>
            </li>
            <li className="menu_item">
              <a href="/contact" className="menu_link">
                Contact
              </a>
            </li>
            <li className="menu_item">
              <a
                target="_blank"
                rel="noreferrer"
                className="menu_link"
                href="https://stage.dltm.quanttradingfactory.com"
              >
                Login
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
