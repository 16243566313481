/* eslint-disable jsx-a11y/anchor-is-valid */ 
const Footer = () => (
  <footer className="footer">
    <div className="footer_body _container">
      <a href="/imprint" onClick={ () => false }  className="menu_link">
        Imprint & Privacy
      </a>
    </div>
  </footer>
);

export default Footer;
