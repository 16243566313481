import Header from './Header';

const ImprintComponent = () => {
  return (
    <div className="app_custody">
      <div className="wrapper_custody">
        <Header />
        <main className="main">
          <section className="main_imprint_body _container">
            <div className="main_imprint_block_body">
              <div className="imprint_privacy_container">
                <div className="imprint_privacy_title">
                  Legal & Privacy
                </div>
                <div className="imprint_privacy_address">
                  DLT Finance Holding AG<br />
                  Tiroler Straße 36<br />
                  60596 Frankfurt<br />
                  Deutschland
                </div>
                <div className="imprint_privacy_contact">
                  Tel: ‭+49 (0) 69 21004103‬<br />
                  Fax: +49 (0) 69 67779489<br />
                  Email: <a href="mailto: contact@dlt-finance.com">contact@dlt-finance.com</a>
                </div>
                <div className="imprint_privacy_text">
                  Vorstand: Michael Kramer‬<br />
                  Aufsichtsrat: Dr. Mario Frick, Marius Sewing, Edi Wögerer‬<br />
                  Register number: HRB 118861, Commercial register, Amtsgericht, Frankfurt am Main, Germany<br />
                  VAT number: DE329185287, German tax administration
                </div>
              </div>
              <div className="terms_container">
                <div className="terms_title">
                  Terms of Use
                </div>
                <div className="terms_text">
                  Persons who access information published on the website of DLT Finance Holding AG (hereinafter referred to as “DLTFH”) or who obtain publications of DLTFH containing financial analysis declare that they agree to the following terms and conditions. Any possible special agreements or special conditions pertaining to individual services and products of DLTFH apply in addition to these conditions. In the event of a contradiction, the special agreements or special conditions will have precedence.
                </div>
                <div className="non_binding_title">
                  Non-binding nature
                </div>
                <div className="non_binding_text">
                  The information (messages, subscription documents, prospectuses, brochures, etc.) published on the website is provided exclusively for information purposes and does not represent a recommendation or an offer or an invitation to buy or sell investment instruments, perform transactions or conclude any possible legal transaction. This applies in particular to persons domiciled in the US or having US nationality.<br /><br />
                  Furthermore, the information published does not represent any decision-making guidance for business, legal, tax-related or other consultancy questions. In addition, no investment or other decisions may be made solely on the basis of this information. In the case of investment decisions, please contact a qualified specialist at DLTFH.<br /><br />
                  The website of DLTFH is not intended for persons who are subject to a jurisdiction which prohibits the publication or access to the website of DLTFH (due to the nationality of the respective person or company, their place of residence or for other reasons). Persons to whom such restrictions apply are not permitted to access the website of DLTFH.
                </div>
                <div className="guarantee_title">
                  No warranty/no guarantee
                </div>
                <div className="guarantee_text">
                  All information has been compiled by DLTFH with great care. The information and opinions presented here originate from sources, which DLTFH deems to be reliable. DLTFH accepts no warranty for the accuracy, comprehensiveness and topicality of the information published on the website. The content of the website may be amended at any time and without prior announcement.<br /><br />
                  This offer contains several links to external websites of third parties, over which DLTFH has no influence. DLTFH therefore cannot accept liability for this third-party content. The relevant website provider, author or operator is responsible for the content of the sites linked to, which were checked by DLTFH personally at the time of linking for any possible legal violations. While no unlawful content was found at the time of linking, it is not possible to constantly check the content of the linked sites if there is no concrete indication that any laws have been violated. Should any violations be found to have occurred, such links will be deleted immediately.
                </div>
                <div className="risk_title">
                  Warning about risks
                </div>
                <div className="risk_text">
                  DLTFH draws attention to the fact that the future performance of investments cannot be deduced from past stock price performances. The value of an investment can rise or fall, and in an extreme case may lead to the loss of the deployed capital. Investments made in foreign currencies are also subject to currency fluctuations. Investments involving high volatility can be subject to high price fluctuations. These price fluctuations may equal or even exceed the value of the invested amount. The preservation of the invested capital cannot therefore be guaranteed. Detailed information about risks can be obtained from DLTFH or from the brochure “Risks in securities trading”.
                </div>
                <div className="restrictions_title">
                  Liability restrictions
                </div>
                <div className="restrictions_text">
                  DLTFH accepts no liability for losses or damage of any nature whatsoever (both direct and indirect as well as consequential damage) which could arise out of the use of or access to the website of DLTFH or from the use of one of our publications. The published information is exploited and utilised at the risk of the individual user.
                </div>
                <div className="third_party_title">
                  Third-party websites (links)
                </div>
                <div className="third_party_text">
                  Links on the website of DLTFH may contain references to the websites of third parties. DLTFH accepts no responsibility whatsoever for the accuracy, completeness and legality of the content of such websites, or for any possible offers (products, services, recommendations, etc.) contained therein.
                </div>
                <div className="use_website_title">
                  Use of the website
                </div>
                <div className="use_website_text">
                  The entire content and the structure of the website are protected by copyright. Users may download and temporarily save one or more of these pages for the purpose of viewing these at a specific location. Individual pages and/or sections of the website may be saved or printed out, insofar as neither the copyright notices nor other legally protected designations of DLTFH are removed. All property rights are retained by DLTFH. The complete or partial reproduction, amendment, utilisation or forwarding of all content, charts and information, transmission (by electronic or other means), modification or use of the website for public or commercial purposes is prohibited without the prior written approval of DLTFH.
                </div>
                <div className="communication_title">
                  Notice concerning e-mail communication
                </div>
                <div className="communication_text">
                  E-mail messages and any attached documents may contain confidential information and are intended only for the named addressee(s). Any disclosure, dissemination or use by persons or entities other than the intended recipient is prohibited. If you have received a message in error, please notify the sender and delete the material from your system. DLTFH accepts no liability for transmission errors or other errors during transmission. Information on the processing of personal data by DLTFH can be found in the data privacy statement.
                </div>
                <div className="investments_title">
                  Own investments
                </div>
                <div className="investments_text">
                  Providing it is permitted pursuant to the prevailing legal and/or regulatory provisions, DLTFH and its employees may participate in financial transactions of the securities issuer(s) mentioned in this publication, or invest in their securities, provide services to these issuers or approach them for orders and/or hold positions in their securities, options on these securities or on investments connected with them, have other substantial interests in them or carry out transactions with them.
                </div>
                <div className="privacy_policy_title">
                  Privacy Policy
                </div>
                <div className="privacy_policy_text">
                  of DLT Finance Holding AG (“DLT”)
                </div>
                <div className="privacy_policy_text_date">
                  Last Update: September 2020
                </div>
                <div className="privacy_policy_text">
                  The protection of your personal date is very important to us. We would like to inform you about the management of your personal data in accordance with Art. 13, 14 General Data Protection Regulation (GDPR).
                </div>
                <div className="controller_title">
                  1 Controller
                </div>
                <div className="controller_text">
                  The controller responsible for the described data collection and processing is the DLT Finance Holding AG, Tiroler Straße 36, 60596 Frankfurt, Germany (“DLT”).
                </div>
                <div className="personal_data_title">
                  2 Usage of Personal Data
                </div>
                <div className="personal_data_text">
                  When you visit our website, the data collected from the use of the website is temporarily stored on our web server for statistical purposes in order to improve the quality of our website.<br /><br />
                  This data set contains:<br /><br />
                  <ul>
                    <li>
                      the page, from which the data is requested,
                    </li>
                    <li>
                      the name of the data file,
                    </li>
                    <li>
                      the date and time of the query,
                    </li>
                    <li>
                      the amount of data transferred,
                    </li>
                    <li>
                      the access status (file transmitted, file not found),
                    </li>
                    <li>
                      a description of the type of browser used,
                    </li>
                    <li>
                      the IP address of the requesting computer shortened to such an extent that no reidentification of any persona data is possible.
                    </li>
                  </ul><br /><br />
                  Should you have entered into a service agreement with DLT, the lawfulness of processing your data is based on Art. 6 para 1 lit. b GDPR, according to which processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract.
                </div>
                <div className="analytics_title">
                  3 Google Analytics
                </div>
                <div className="analytics_text">
                  We use Google Analytics to create pseudonymous user profiles for improving and designing our website on demand. Google Analytics uses “cookies”, which are text files placed on your end device and can be read by us. In this way, we are able to recognize and count returning visitors. This data processing is carried out on the basis of Art. 6 para. 1 s. 1 lit. f GDPR and in the interest of finding out how often our website is viewed by different users.<br /><br />
                  The information generated by the cookies about your use of the website will be transmitted to and stored by Google on its servers in the United States. We have activated IP-anonymization on this website, your IP address will be truncated within the area of Member States of the European Union.<br /><br />
                  Only in exceptional cases is the whole IP address transferred to a Google server in the USA and truncated there. Accordingly, Google will solely use collected data for the purposes intended, which are to evaluate the use of the website and to compile reports on website activities.<br />
                  DLT considers that the legal base for doing its activities is Art. 6 para. 1 s. 1 lit. f GDPR (data processing for the purposes of the legitimate interests)<br /><br />
                  You can withdraw your consent to the processing at any time.<br /><br />
                  You may use one of the following options:<br /><br />
                  <ul>
                    <li>
                      You may refuse the use of cookies by selecting the appropriate settings on your browser, however, please note that if you proceed accordingly you may not be able to benefit from the full functionality of this website.
                    </li>
                    <li>
                      You can also prevent Google from collecting the data generated by the cookie and relating to your use of the website (including your IP address) and from processing this data by downloading and installing the browser plug-in.
                    </li>
                    <li>
                      As an alternative to opting out from being tracked by Google Analytics you can also install and store an opt-out cookie, which means that no web analysis will take place as long as the opt-out cookie is stored by your browser.
                    </li>
                  </ul><br /><br />
                  Should you have any question, please reach out to us at:&nbsp;
                  <a href="mailto: dataprotection@dlt-finance.com">dataprotection@dlt-finance.com</a>.
                </div>
                <div className="adwords_title">
                  4 Use of Google AdWords Conversion Tracking
                </div>
                <div className="adwords_text">
                  This website uses the online advertising tool "Google AdWords" and, within the framework of Google AdWords, the conversion tracking of Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland ("Google").<br /><br />
                  When you submit our contact survey, it will be reported to Google. This takes place via a so-called "conversion snippet". We also store the Google ID in our salesforce.com CRM system, The Landmark One Market Street, Suite 300, San Francisco, CA 94105, USA, for the purpose of matching leads.<br /><br />
                  We use the services of Google Adwords to draw attention to our attractive offers with the help of advertising material (so-called Google Ads) on external websites. In relation to the data of the advertising campaigns, we can determine how successful the individual advertising campaigns are. In doing so, we pursue the interest of showing you content that is of interest to you, of making our website more engaging for you and of achieving a fair calculation of advertising costs.<br /><br />
                  The conversion tracking cookie is set when a user clicks on a GoogleAds ad placed by Google. If the user visits certain pages on this site and the cookie has not expired, Google and we can recognise that the user clicked on the ad and was directed to that page. Each Google AdWords customer receives a different cookie. As a result, cookies cannot be tracked through the websites of AdWords customers. The information collected from the Conversion cookie is used to generate conversion statistics for AdWords customers who have opted for Conversion Tracking. Customers will know the total number of users who clicked on their ad and were directed to a page with a conversion tracking tag. However, they will not receive information that personally identifies users. If you do not wish to participate in tracking, you can block this use. You will then no longer be included in the conversion tracking statistics.<br /><br />
                  The legal basis for the described data processing is your consent according to Art. 6 para. 1 lit. a GDPR.<br /><br />
                  Should you have any question, please reach out to us at:&nbsp;
                  <a href="mailto: dataprotection@dlt-finance.com">dataprotection@dlt-finance.com</a>.
                </div>
                <div className="linked_in_title">
                  5 LinkedIn Ads
                </div>
                <div className="linked_in_text">
                  For the purpose of conversion tracking, we use the "LinkedIn Ads" service, a service of the LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Ireland (hereinafter referred to as "LinkedIn").<br /><br />
                  LinkedIn Ads processes statistical, pseudonymous data about your visit and use of our website and about the use of our information services on LinkedIn using a pixel that we have integrated on our website.<br /><br />
                  The legal basis for this is your consent, which you can revoke at any time.<br /><br />
                  You can prevent LinkedIn from collecting the aforementioned information by making the appropriate setting.<br /><br />
                  Should you have any question, please reach out to us at:&nbsp;
                  <a href="mailto: dataprotection@dlt-finance.com">dataprotection@dlt-finance.com</a>.
                </div>
                <div className="twitter_title">
                  6 Twitter Ads
                </div>
                <div className="twitter_text">
                  For the purpose of conversion tracking, we use the “Twitter Ads” service, a service of the Twitter, Inc., 1355 Market Street, San Francisco, CA 94103, USA, (hereinafter referred to as “Twitter”).<br /><br />
                  Twitter Ads processes statistical, pseudonymous data about your visit and use of our website and about the use of our information services on Twitter using a pixel that we have integrated on our website.<br /><br />
                  The legal basis for this is your consent, which you can revoke at any time.<br /><br />
                  Should you have any question, please reach out to us at:&nbsp;
                  <a href="mailto: dataprotection@dlt-finance.com">dataprotection@dlt-finance.com</a>.
                </div>
                <div className="data_transfer_title">
                  7 Data Transfer To Third Parties – including transfer to countries outside of the EU
                </div>
                <div className="data_transfer_text">
                  We transfer your data to service providers who support us in the operation of our website and the associated processes. This transference is made under the scope of a data processing agreement in accordance to Art. 28 GDPR. Our service providers are bound to us by strict instructions and contractual obligations.<br /><br />
                  In some cases, we may transfer personal data to third countries outside the EU. In each case, we ensure an appropriate level of data protection according to European standards.
                </div>
                <div className="rights_title">
                  8 Your Rights as a User
                </div>
                <div className="rights_text">
                  You have the right to obtain confirmation whether or not personal data concerning you is being processed, and, where that is the case access to the personal data and the information specified in Art. 15 GDPR. You also have the right to obtain an erasure of the personal data concerning you, if one of the reasons listed in Art. 17 GDPR applies. In certain situations, listed in Art. 20 GDPR, you have the right to data portability. If the data is processed pursuant to Art. 6 para. 1 s. 1 lit. f GDPR (data processing for
                  the purposes of the legitimate interests), you have the right to object to the processing at any time for reasons arising out of your particular situation. We will then no longer process personal data, unless there are demonstrably compelling legitimate grounds for processing, which override the interests, rights and freedoms of the person concerned, or the processing serves the purpose of asserting, exercising or defending legal claims. You have the right to lodge a complaint with a supervisory authority.<br /><br />
                  Please contact our data protection officer, if you have any further questions, suggestions or wishes regarding data protection for example by e-mail to:&nbsp;
                  <a href="mailto: dataprotection@dlt-finance.com">dataprotection@dlt-finance.com</a>.
                </div>
                <div className="processing_title">
                  9 Processing Of Your Data
                </div>
                <div className="processing_text">
                  We process personal data (e. g. name, e-mail-address and phone number) in order to establish and maintain contact to external parties such as potential partners, providers or consultants. You have either provided your contact details yourself or we gathered them from external sources such as your company’s website, conference materials, market reports, LinkedIn or Xing.<br /><br />
                  The data processing is based on Art. 6 Para. 1 lit f GDPR and in the interests of lead generation and customer relationship management. The data will be deleted after it is no longer necessary for the purpose and if there is no legal obligation for storage of data. Our usual retention periods cover a time frame from 4 to 10 years.<br /><br />
                  We transfer your data to service providers who support us in the operation of our website and the associated processes. This transference is made under the scope of a data processing agreement in accordance to Art. 28 GDPR. Our service providers are bound to us by strict instructions and contractual obligations. In some cases, we may transfer personal data to third countries outside the EU. In each case, we ensure an appropriate level of data protection according to European standards.
                </div>
                <div className="protection_title">
                  10 Your Data Protection Rights
                </div>
                <div className="protection_text">
                  You have the right to obtain confirmation whether or not personal data concerning you is being processed, and, where that is the case access to the personal data and the information specified in Art. 15 GDPR. You also have the right to obtain an erasure of the personal data concerning you, if one of the reasons listed in Art. 17 GDPR applies. In certain situations, listed in Art. 20 GDPR, you have the right to data portability. If the data is processed pursuant to Art. 6 para. 1 s. 1 lit. f GDPR (data processing
                  for the purposes of the legitimate interests), you have the right to object to the processing at any time for reasons arising out of your particular situation. We will then no longer process personal data, unless there are demonstrably compelling legitimate grounds for processing, which override the interests, rights and freedoms of the person concerned, or the processing serves the purpose of asserting, exercising or defending legal claims. You have the right to lodge a complaint with a supervisory authority.<br /><br />
                  To exercise the above rights, such as the deletion of your data or information on which data is stored, please contact: Should you have any question, please reach out to us at:&nbsp;
                  <a href="mailto: dataprotection@dlt-finance.com">dataprotection@dlt-finance.com</a>.<br /><br />
                  For other enquiries or to report a personal data breaches, please contact: Should you have any question, please reach out to us at:&nbsp;
                  <a href="mailto: dataprotection@dlt-finance.com">dataprotection@dlt-finance.com</a>.
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default ImprintComponent;
