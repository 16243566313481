
import { useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Header from './Header';
import Footer from './Footer';
import lock from '../imgs/lock.svg';
import oneAccess from '../imgs/one_access_tab.png';
import easyUsability from '../imgs/easy_usability.png';
import highPerformance from '../imgs/high_performance.png';
import counterpartyRisk from '../imgs/counterparty_risk.png';
import superiorProducts3 from '../imgs/superior_products_3.png';
import superiorProducts24 from '../imgs/superior_products_24.png';
import bankingIntegration from '../imgs/banking_integration.png';

const MarketsComponent = () => {
  const location = useLocation();
  return (
    <div className="app__markets">
      <div className="wrapper_markets">
        <Header />
        <main className="main">
          <section className="main_markets _container">
            <div className="main_markets_body">
              <div className="main_markets_body_container">
                <div className="main_markets_body_wrap">
                  <div className="main_markets_body_before">
                    The prime brokerage technology for digital assets.
                  </div>
                  <div className="main_markets_body_title">
                    Institutional access into digital assets
                  </div>
                  <div className="main_markets_body_text">
                    DLT Markets sets the new standard for institutional investors entering digital assets. We bridge the traditional and new world of finance, setting benchmarks in performance, usability & security.
                  </div>
                  <div className="main_markets_body_button">
                    <a href={`${location.pathname}#institutions`} className="menu_link custody_security_button_border">
                      Reed more
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="main_bg _ibg">
                <img src={custody_body_bg} alt="bg" />
              </div> */}
              {/* <div className="main_custody_body_button">Read more</div> */}
            </div>
          </section>
          <section id="institutions" className="markets_institutions _container">
            <div className="institutions_container">
              <div className="institutions_block">
                <div className="institutions_block_title">
                  Traditional Financial Institutions
                </div>
                <div className="institutions_block_text">
                  Fully regulated multi exchange platform with central counterpart system and integrated centralized custody. A MiFid compliant best market execution provides trust and transparency.
                </div>
              </div>
              <div className="institutions_block">
                <div id="pentagon" className="institutions_block_border">
                  <span></span>
                  <div className="institutions_block_title">
                    DLT Markets
                  </div>
                  <div className="institutions_block_text">
                    Regulated digital asset trading with reduced counterparty risk for financial institutions.
                  </div>
                </div>
              </div>
              <div className="institutions_block">
                <div className="institutions_block_title">
                  Digital Asset Market
                </div>
                <div className="institutions_block_text">
                  24/7 access to a new asset class. Digital assets allow new trading opportunities but are missing regulatory coverage. They are allocated at multiple exchangesmaking them hard to access.
                </div>
              </div>
            </div>
          </section>
          <section className="markets_benefits _container">
            <div className="markets_benefits_body">
              <div className="markets_benefits_body_container">
                <div className="markets_benefits_body_wrap">
                  <div className="main_markets_body_before">
                    Core customer benefits
                  </div>
                  <div className="main_markets_body_title">
                    Get access to new trading opportunities.
                  </div>
                  <div className="main_markets_body_text">
                    Regulated digital asset tradingwith reduced counterparty risk for financial institutions.
                  </div>
                </div>
                <div className="markets_benefits_icons">
                  <div className="markets_benefits_icon">
                    <div className="performance_hover">
                      <div className="circle_container">
                        <div className="circle_style">
                          <span>
                            <img src={highPerformance} alt="bg" />
                          </span>
                          <div className="pulse-ring"></div>
                          <div className="red-medium-circle"></div>
                          <div className="red-big-circle"></div>
                          <div className="red-huge-circle"></div>
                        </div>
                      </div>
                    </div>
                    <div className="markets_benefits_text_performance">
                      Low latency trading platform<br /> for real-time trading and transfer.
                    </div>
                    <div className="banking_hover_text">High Performance</div>
                  </div>
                  <div className="markets_benefits_icon">
                    <div className="usability_hover">
                      <div className="circle_container">
                        <div className="circle_style">
                          <span className='markets_benefits_lock'>
                            <img src={lock} alt="bg" />
                          </span>
                          <div className="pulse-ring"></div>
                          <div className="red-medium-circle"></div>
                          <div className="red-big-circle"></div>
                          <div className="red-huge-circle"></div>
                        </div>
                      </div>
                    </div>
                    <div className="markets_benefits_text_usability">
                      Regulated digital asset<br /> trading and secure storage.
                    </div>
                    <div className="banking_hover_text">Security</div>
                  </div>
                  <div className="markets_benefits_icon">
                    <div className="banking_hover">
                      <div className="circle_container">
                        <div className="circle_style">
                          <span>
                            <img src={easyUsability} alt="bg" />
                          </span>
                          <div className="pulse-ring"></div>
                          <div className="red-medium-circle"></div>
                          <div className="red-big-circle"></div>
                          <div className="red-huge-circle"></div>
                        </div>
                      </div>
                    </div>
                    <div className="markets_benefits_text_banking">
                      Direct assets to all exchanges and<br /> simple online banking integration.
                    </div>
                    <div className="banking_hover_text">Easy Usability</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="markets_api _container">
            <div className="markets_api_body">
              <div className="markets_api_body_container">
                <div className="markets_api_body_wrap">
                  <div className="markets_api_body_before">
                    Learn about how it works
                  </div>
                  <div className="markets_api_body_title">
                    Plug-n-play with easy API integration
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="markets_tabs _container">
            <div className="markets_tabs_body">
              <Tabs>
                <TabList>
                  <Tab>
                    <p>One access</p>
                  </Tab>
                  <Tab>
                    <p>Superior Products & Reporting</p>
                  </Tab>
                  <Tab>
                    <p>Instant settlement & banking integration</p>
                  </Tab>
                  <Tab>
                    <p>Reduced counterparty risk</p>
                  </Tab>
                  <Tab>
                    <p>Secure custody</p>
                  </Tab>
                </TabList>
                <TabPanel>
                  <div className="panel-content">
                    <h2>Combined access to multiple exchanges, miners and other liquidity providers. Direct trading from your bank account - taking the hassle out of transferring assets.</h2>
                    <div className="one_access_img">
                      <img className="one_acess_margin" src={oneAccess} alt="bg" />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="panel-content">
                    <h2>DLT Markets offers Portfolio Management Technology, Advanced Order Types and Risk Management with a sophisticated monitoring system. Our platform solution offers instant access to smart order routing through a consolidated order book and in-depth trade reporting.</h2>
                    <div className="one_access_img superior_products_img">
                      <img className="superior_products_img1" src={superiorProducts3} alt="bg" />
                      <img className="superior_products_img2" src={superiorProducts24} alt="bg" />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="panel-content">
                    <h2>By using our state of the art product we are able to offer a seamless, easy to use integration into your current banking system. Our product utilizes real-time trading standards for settling trades the traditional way - instantly.</h2>
                    <div className="one_access_img">
                      <img src={bankingIntegration} alt="bg" />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="panel-content">
                    <h2>A fully regulated bank acts as a central counterpart. No need to trade against unregulated crypto exchanges. We are setting new standards to make digital asset trading more secure.</h2>
                    <div className="one_access_img">
                      <img className="counterparty_risk_margin" src={counterpartyRisk} alt="bg" />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="panel-content">
                    <h2>Connected to, and jointly designed with the world leading and most secure custody providers, covering diverse technologies, legal and geographic needs.</h2>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default MarketsComponent;
