import { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { styled } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Header from './Header';
import Footer from './Footer';
import contact_bg from '../imgs/contact_bg.jpg';

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  color: '#fff',
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#0063cc',
  borderColor: '#0063cc',
  fontFamily: [
    'Inter',
  ].join(','),
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ContactComponent = () => {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);

  const onRoleNameChange = (evt) => {
    const { value } = evt.target;
    setEmail(value);
  };

  const onSubscribe = () => {
    console.log('onSubscribe');
  };

  const validateEmail = (str) => regEx.test(String(str).toLowerCase());

  useEffect(() => {
    const validEmail = validateEmail(email);
    setIsValidEmail(validEmail);
  }, [email]);

  return (
    <div className="app__markets">
      <div className="wrapper_markets">
        <Header />
        <main className="main">
          <section className="conatct_us _container">
            <div className="contact_container">
              <div className="contact_form">
                <div className="contact_form_text">
                  For more information regarding the different product and services, please contact us and we will get back to you with detailed information
                </div>
                <div className="contact_form_link">
                  <FormControl style={{ width: '100%' }} variant="outlined">
                    <TextField
                      value={email}
                      onChange={onRoleNameChange}
                      placeholder="Your Email"
                      fullWidth
                      margin="normal"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                    <BootstrapButton
                      disableRipple
                      variant="contained"
                      disabled={!isValidEmail}
                      onClick={() => onSubscribe()}
                    >
                      Get all the info
                    </BootstrapButton>
                  </FormControl>
                </div>
              </div>
              <div className="contact_bg">
                <img src={contact_bg} alt="bg" />
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default ContactComponent;
