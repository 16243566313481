import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import logo_b from '../imgs/logo_dltb.png';
import logo_c from '../imgs/logo_dltc.png';
import logo_m from '../imgs/logo_dltm.png';


const blockStyle = () => ({
  icon: {
    cursor: 'pointer',
    color: '#4ce1b6',
  },
});

const DLTFinanceComponent = () => (
  <main className="main">
    <section className="main_body _container">
      <div className="main_block_body">
        <div className="main_body_container">
          <div className="main_body_before">
            The tailored prime solution for digital assets.
          </div>
          <div className="main_body_title">
            The Ecosystem for digital Assets
          </div>
          <div className="main_body_text">
            DLT Finance offers tailored prime solutions for professional investors entering the space of digital assets.
            We combine state of the art technology with regulatory expertise to build a setup,
            which is as sophisticated as in the traditional financial markets.
          </div>
        </div>
      </div>
      <div className="main_block_list">
        <div className="main_block_list_item">
          <span className="main_block_list_icon">
            <FontAwesomeIcon
              icon={faPlus}
              style={blockStyle().icon}
            />
          </span>
          <a href="/" className="menu_link">
            Banking
          </a>
        </div>
        <div className="main_block_list_item">
          <span className="main_block_list_icon">
            <FontAwesomeIcon
              icon={faPlus}
              style={blockStyle().icon}
            />
          </span>
          <a href="/custody" className="menu_link">
            Regulated Custody
          </a>
        </div>
        <div className="main_block_list_item">
          <span className="main_block_list_icon">
            <FontAwesomeIcon
              icon={faPlus}
              style={blockStyle().icon}
            />
          </span>
          <a href="/brokerage" className="menu_link">
            Trading Platform
          </a>
        </div>
      </div>
    </section>
    <section id="solutions" className="solutions_body _container">
      <div className="solutions_body_wrap">
        <div className="solutions_body_headline">
          The all-in-one solution for your digital asset needs
        </div>
        <div className="solutions_body_button">
          {/* <Button
              size="lg"
              // href="https://stage.dltm.quanttradingfactory.com"
              // type="button"
              // outline={false}
              outline
              color="success"
              // variant="outline-success"
              // color="primary"
              onClick={becomeACtient}
              // disabled={!isValidToAdd}
              // className="icon modal_ok square"
              // style={{ margin: '0 auto' }}
            >
              Become a client
            </Button> */}
          {/* <Button
              outline
              size="sm"
              type="button"
              outline={false}
              color="primary"
              className="icon modal_ok square"
              onClick={() => becomeACtient()}
            >
              Become a client
            </Button> */}
          <a href="https://stage.dltm.quanttradingfactory.com" className="menu_link solutions_body_button_border">
            Become a client
          </a>
        </div>
      </div>
    </section>
    <section className="cards_body _container">
      <div className="card_body">
        <div className="card_logo">
          <img src={logo_b} className="" alt="logo" />
        </div>
        <div className="card_sub_title card_sub_title_color_b">
          The missing part of the equation.
        </div>
        <div className="card_title">
          Soon to be: The regulatory cover
        </div>
        <div className="card_text">
          DLTB attempts to complete the DLT Finance ecosystem for digital assets.
          The aim is to extend the product range by those products and services,
          which require certain regulations.
          (Details regarding the regulatory cover are following)
        </div>
        <div className="card_button">
          <a href="/" className="menu_link card_button_border card_button_color_b">
            Coming soon
          </a>
        </div>
      </div>
      <div className="card_body">
        <div className="card_logo">
          <img src={logo_c} className="" alt="logo" />
        </div>
        <div className="card_sub_title card_sub_title_color_c">
          Regulated Multi-layer Security Infrastructure for digital Assets.
        </div>
        <div className="card_title">
          Institutional grade access to digital asset custody
        </div>
        <div className="card_text">
          DLTC offers the safekeeping of multiple digital Assets with state of the art technology and security.
          Being regulated under the German BaFin, we provide our clients the same regulatory
          Security as they are used to from traditional financial markets.
        </div>
        <div className="card_button card_button">
          <a href="/custody" className="menu_link card_button_border card_button_color_c">
            Visit DLTCustody
          </a>
        </div>
      </div>
      <div className="card_body">
        <div className="card_logo">
          <img src={logo_m} className="" alt="logo" />
        </div>
        <div className="card_sub_title card_sub_title_color_m">
          The prime brokerage technology for digital assets.
        </div>
        <div className="card_title">
          Institutional access into digital assets
        </div>
        <div className="card_text">
          DLT Markets sets the new standard for institutional investors entering digital assets.
          We bridge the traditional and new world of finance, setting benchmarks in performance, usability & security.
        </div>
        <div className="card_button">
          <a href="/brokerage" className="menu_link card_button_border card_button_color_m">
            Visit DLTMarkets
          </a>
        </div>
      </div>
    </section>
  </main>
);

export default DLTFinanceComponent;
