import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import CustodyComponent from './components/CustodyComponent';
import MarketsComponent from './components/MarketsComponent';
import ImprintComponent from './components/ImprintComponent';
import ContactComponent from './components/ContactComponent';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/custody" element={<CustodyComponent />} />
      <Route path="/imprint" element={<ImprintComponent />} />
      <Route path="/brokerage" element={<MarketsComponent />} />
      <Route path="/contact" element={<ContactComponent />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
